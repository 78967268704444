<template>
  <div class="w-full">
    <div>
      <label
        class="flex w-full flex-row items-center justify-between gap-4 rounded-lg transition-all"
        :class="{ 'cursor-pointer  ': !disabled }"
      >
        <div
          :tabindex="disabled ? -1 : 0"
          class="flex flex-1 select-none flex-row-reverse items-center justify-between gap-2 outline-[1.5px] outline-success-100 focus-visible:outline md:flex-row md:justify-start"
          @click="toggle"
        >
          <slot name="icon">
            <UiIcon
              name="chevron-big-right-circle-outlined"
              :size="isMobile || compact ? 'xs' : 'sm'"
              :class="[
                'transition-all duration-200',
                { 'rotate-180': modelValue },
                disabled ? 'text-black-60' : 'text-black-70',
              ]"
            ></UiIcon>
          </slot>
          <slot name="title">
            <div class="flex items-center gap-1">
              <h4 v-if="big" :class="{ 'text-black-40': disabled }">{{ title }}</h4>
              <h5 v-else :class="{ 'text-black-40': disabled, big, 'text-caption': compact }">{{ title }}</h5>
              <UiTooltip v-if="tooltipText" :width="200" position-right :name="`${title}_tooltip`">
                <template #activator>
                  <UiIcon name="info-circle-filled" class="cursor-pointer text-primary-60" />
                </template>
                <template #content>
                  <div>{{ tooltipText }}</div>
                </template>
              </UiTooltip>
            </div>
          </slot>

          <h5 v-if="subtitle" class="text-subhead-3 -ml-1 text-black-60">{{ subtitle }}</h5>
        </div>
        <UiIcon
          v-if="icon"
          :name="icon"
          size="xs"
          :class="disabled ? 'text-black-60' : 'text-black-70'"
          @click="$emit('action')"
        />
      </label>
    </div>
    <Collapse :when="modelValue" class="height-transition">
      <p v-if="description" class="text-body-2 ml-8 text-black-80">{{ description }}</p>
      <div :class="spaceBetweenContentClass">
        <slot />
      </div>
    </Collapse>
  </div>
</template>

<script setup lang="ts">
import { Collapse } from 'vue-collapsed'

const emits = defineEmits(['update:modelValue', 'action'])

type Props = {
  modelValue: boolean | undefined
  title: string
  tooltipText?: string
  description?: string
  icon?: string
  disabled?: boolean
  big?: boolean
  subtitle?: string
  spaceBetweenContentClass?: string
  compact?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  icon: '',
  description: '',
  subtitle: '',
  tooltipText: '',
  spaceBetweenContentClass: 'mt-4',
})

const toggle = () => {
  if (props.disabled) return
  emits('update:modelValue', !props.modelValue)
}

const { smaller } = useBreakpoints(useGetBreakpoints())

const isMobile = smaller('xs')
</script>

<style scoped></style>
